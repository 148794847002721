<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow
        width="600"
        height="180"
        :steps="workflowSteps"
        :currentStep="workflowIndex"
        :labelWidth="200"
        :lineWidth="140"
      ></Workflow>
    </div>

    <v-row justify="center">
      <v-col cols="10">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="Création d'un praticien" />
        </div>

        <!-- la progess bar à afficher lors du chargement des données -->
        <v-progress-linear
          indeterminate
          :active="loading || running"
        ></v-progress-linear>

        <v-card flat outlined class="mx-auto">
          <v-card-title>
            <v-row no-gutters>
              <!-- Titre de la table -->
              <div class="font-weight-regular">
                Créer un praticien collaborateur
              </div>
            </v-row>
          </v-card-title>

          <!-- Recherche de l'utilisateur -->
          <v-card-text class="pb-1">
            <v-autocomplete
              :items="users"
              v-model="user"
              :search-input.sync="search"
              no-data-text
              clearable
              @input="selected"
              :loading="loading || running"
              label="Saisir l'adresse mail du collaborateur"
            ></v-autocomplete>
          </v-card-text>

          <!-- Indique que le collaborateur ne fait pas partie du service Médecin -->
          <v-card-text v-if="viewMessageUserHasNotServiceMedecin" class="pb-4">
            <div class="text-h6 font-weight-regular error--text">
              Le collaborateur sélectionné ne possède pas de fonction associée
              au service Médecin.
            </div>
          </v-card-text>

          <!-- Indique que le collaborateur est déjà un collaborateur praticien -->
          <v-card-text v-if="userIsCollaboraterPractitioner" class="pb-4">
            <div class="text-h6 font-weight-regular error--text">
              Le collaborateur sélectionné est déjà un praticien collaborateur.
            </div>
          </v-card-text>

          <div v-if="viewDatasToCreate">
            <v-card-text class="pt-1">
              <v-row no-gutters justify="end">
                <span class="red--text text-body-2" justify="end"> * </span>
                <span class="text-body-2 ml-1" justify="end">
                  <em> champs obligatoires</em>
                </span>
              </v-row>
              <v-row no-gutters align="center" style="flex-wrap: nowrap">
                <!-- Civilité d'exercice -->
                <v-autocomplete
                  class="flex-grow-0 flex-shrink-1"
                  :items="exerciseCivilities"
                  item-text="name"
                  v-model="exerciseCivility"
                  return-object
                ></v-autocomplete>

                <!-- Identité du praticien -->
                <div class="text-h6 font-weight-bold mx-4">
                  {{ firstname }}
                  {{ name }}
                </div>

                <!-- genre du praticien -->
                <v-autocomplete
                  class="flex-grow-0 flex-shrink-1"
                  :items="genders"
                  v-model="gender"
                ></v-autocomplete>
                <span class="red--text">*</span>
              </v-row>

              <!-- Numéro Rpps du praticien -->
              <v-row no-gutters align="center">
                <v-col cols="3">
                  <div class="my-2 capitalize font-weight-regular">
                    numéro RPPS <span class="red--text">*</span> :
                  </div>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    class="inputPrice"
                    placeholder="Le numéro RPPS"
                    dense
                    v-model="practitionerIdpp"
                    :rules="[
                      rules.validateRPPSFormat,
                      rules.practitionerIsExternal,
                    ]"
                    :error-messages="errorsMessageIdpp"
                    @blur="verifyPractitionerCollaboratorRpps"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <!-- Texte de présentation affiché sur le site Avec -->
              <v-row no-gutters align="center">
                <div class="mt-2 capitalize font-weight-regular">
                  présentation sur le site Avec :
                </div>
              </v-row>
              <v-row no-gutters align="center">
                <v-textarea
                  filled
                  class="mb-6"
                  v-model="presentation"
                  placeholder="Présentation sur le site Avec"
                >
                </v-textarea>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Partie données groupe du praticien -->
            <v-card-text>
              <v-row no-gutters align="center">
                <div class="capitalize text-h6 font-weight-regular">
                  Les données groupe :
                </div>
              </v-row>

              <!-- Email du praticien -->
              <v-row no-gutters align="center">
                <v-col cols="3">
                  <div class="my-2 capitalize font-weight-regular">email :</div>
                </v-col>
                <v-col cols="9">
                  <div class="my-2">{{ email }}</div>
                </v-col>
              </v-row>

              <!-- Etablissement du praticien -->
              <v-row no-gutters align="center">
                <v-col cols="3">
                  <div class="my-2 capitalize font-weight-regular">
                    établissement de rattachement :
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="my-2">{{ establishmentName }}</div>
                </v-col>
              </v-row>

              <!-- Activité du praticien -->
              <v-row no-gutters align="center">
                <v-col cols="3">
                  <div class="my-2 capitalize font-weight-regular">
                    activité :
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="my-2">{{ activityName }}</div>
                </v-col>
              </v-row>

              <!-- Fonction primaire du praticien -->
              <v-row no-gutters align="center">
                <v-col cols="3">
                  <div class="my-2 capitalize font-weight-regular">
                    fonction :
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="my-2">{{ primaryFunction }}</div>
                </v-col>
              </v-row>

              <!-- Fonctions secondaires du praticien -->
              <v-row no-gutters align="center">
                <v-col cols="3">
                  <div class="my-2 capitalize font-weight-regular">
                    fonction(s) secondaire(s) :
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="my-2">{{ secondaryFunction }}</div>
                </v-col>
              </v-row>

              <!-- Titre d'employé du praticien -->
              <v-row no-gutters align="center">
                <v-col cols="3">
                  <div class="my-2 capitalize font-weight-regular">
                    titre employé :
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="my-2">{{ employeeTitle }}</div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Partie données de la profession -->
            <v-card-text>
              <!-- Le nom de la profession -->
              <v-row no-gutters align="center">
                <v-col cols="3">
                  <div class="capitalize font-weight-regular">
                    Profession <span class="red--text">*</span> :
                  </div>
                </v-col>
                <v-col cols="9">
                  <v-autocomplete
                    class="flex-grow-0 flex-shrink-1"
                    placeholder="Sélectionner un nom de profession"
                    item-text="label"
                    :items="professionsNames"
                    v-model="selectedProfessionName"
                    no-data-text="aucun nom de profession"
                    @input="selectedProfessionNameAction"
                    clearable
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <!-- Les/la profession médicales -->
              <v-row no-gutters align="center">
                <v-col cols="3">
                  <div class="capitalize font-weight-regular">
                    Profession médicale :
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="my-2">{{ medicalProfession.label }}</div>
                </v-col>
              </v-row>

              <!-- Les/la spécialité médicales -->
              <v-row no-gutters align="center" class="mb-2">
                <v-col cols="3">
                  <div class="capitalize font-weight-regular">
                    Spécialité médicale
                    <span
                      v-if="!disableComboMedicalSpeciality"
                      class="red--text"
                      >*</span
                    >
                    :
                  </div>
                </v-col>
                <v-col cols="9">
                  <v-autocomplete
                    class="flex-grow-0 flex-shrink-1"
                    placeholder=""
                    item-text="name"
                    :items="medicalSpecialities"
                    v-model="selectedMedicalSpeciality"
                    no-data-text="aucune spécialité médicale"
                    :disabled="disableComboMedicalSpeciality"
                    :rules="[rules.validateMedicalSpeciality]"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <v-card-text>
              <!-- Afficher une combo pour l'ajout avec un bouton + -->
              <v-row no-gutters align="center">
                <v-col cols="3">
                  <div class="capitalize font-weight-regular">
                    Ajouter un lieu d'exercice :
                  </div>
                </v-col>
                <v-col cols="8">
                  <v-autocomplete
                    class="flex-grow-0 flex-shrink-1"
                    placeholder="Sélectionner un lieux d'exercice"
                    item-text="name"
                    item-value="name"
                    :items="availablesPracticesPlaces"
                    v-model="selectedPracticePlace"
                    :disabled="disabledComboPracticePlace"
                    no-data-text="aucuns lieux d'exercice"
                    return-object
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="1">
                  <v-btn
                    color="primary"
                    icon
                    @click="addPracticePlace()"
                    :disabled="!canAddPracticePlace"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <!-- La liste des lieux d'exercices -->
              <v-row no-gutters class="mt-4">
                <v-col cols="3">
                  <v-row no-gutters align="center">
                    <div class="capitalize text-h6 font-weight-regular">
                      Le lieu d'exercice <span class="red--text">*</span> :
                    </div>
                  </v-row>
                </v-col>
                <v-col cols="9" class="mt-6">
                  <div v-for="(pp, i) in practicesPlaces" :key="i">
                    <!-- Le nom du lieux d'exercie -->
                    <v-row no-gutters align="center" class="mb-1">
                      <v-col cols="2">
                        <div class="capitalize font-weight-regular">Nom :</div>
                      </v-col>
                      <v-col cols="9">
                        <div class="text-subtitle-1 font-weight-bold">
                          {{ pp.name }}
                        </div>
                      </v-col>
                      <!-- v-if="viewDeletePracticePlace" -->
                      <v-col cols="1">
                        <v-btn
                          icon
                          color="primary"
                          @click="deletePracticePlace(pp)"
                        >
                          <v-icon>mdi-window-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <!-- L'adresse du lieux d'exercice -->
                    <v-row no-gutters align="center">
                      <v-col cols="2" align-self="start">
                        <div class="capitalize font-weight-regular">
                          Adresse :
                        </div>
                      </v-col>
                      <v-col cols="10">
                        <div class="font-weight-regular">
                          {{ addressOfPracticePlace(pp).address }}
                        </div>
                        <div class="font-weight-regular">
                          {{ addressOfPracticePlace(pp).postalCode }}
                          {{ addressOfPracticePlace(pp).city }}
                        </div>
                      </v-col>
                    </v-row>
                    <!-- Le téléphone du lieux d'exercice -->
                    <v-row no-gutters align="center">
                      <v-col cols="2" align-self="start">
                        <div class="capitalize font-weight-regular">
                          Téléphone :
                        </div>
                      </v-col>
                      <v-col cols="10">
                        <div class="font-weight-regular">{{ pp.phone }}</div>
                      </v-col>
                    </v-row>

                    <!-- Les agendas YESDOC -->
                    <v-row no-gutters align="center" class="my-4">
                      <v-col cols="2" align-self="start">
                        <div class="capitalize font-weight-regular">
                          agendas YESDOC :
                        </div>
                      </v-col>
                      <v-col cols="10">
                        <div
                          v-for="(agenda, i) in agendaOfPracticePlace(pp)"
                          :key="'A' + i"
                          class="font-weight-regular"
                        >
                          <v-row no-gutters>
                            <v-col cols="11">
                              {{ agenda }}
                            </v-col>
                            <v-col cols="1">
                              <v-btn
                                icon
                                color="primary"
                                @click="deleteYesdocAgenda(pp, agenda)"
                              >
                                <v-icon>mdi-window-close</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </div>

                        <!-- L'ajout d'un agenda yesdoc -->
                        <v-row no-gutters align="center">
                          <v-col cols="11">
                            <v-text-field
                              placeholder="L'uuid de l'agenda yesdoc"
                              dense
                              v-model="yesdocAgendaToAdd"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="1">
                            <v-btn
                              color="primary"
                              icon
                              @click="addYesdocAgenda(pp)"
                              :disabled="!canAddYesdocAgenda"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="practicesPlaces.length > 1"
                      class="my-4"
                    ></v-divider>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!completed || loading"
                @click="save"
              >
                <div class="capitalize">enregistrer</div>
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <DeleteTableItemDialog
      label="Le lieu d'exercice se trouve au sein de l'établissement de rattachement du praticien."
      :item="practicePlaceToDelete"
      :visible.sync="showDialogDeletePracticePlace"
      @confirmed="onDeletePracticePlaceConfirmed()"
    ></DeleteTableItemDialog>

    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import DeleteTableItemDialog from "@/components/ui/DeleteTableItemDialog.vue";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { PractitionnerService } from "@/service/practitioners/practitioner_service.js";
import { CollaboratorPractitionerService } from "@/service/practitioners/collaborator_practitioner_service.js";
import { MedicalSpecialtiesService } from "@/service/practitioners/medical_specialties_service.js";
import { UsersService } from "@/service/user/dg_user_service.js";
import { CollaboratorsService } from "@/service/user/collaborators_service.js";
import { FonctionService } from "@/service/sfr/function_service.js";
import EstablishmentsService from "@/service/etablishment/etablishment_service.js";
import { ActivityService } from "@/service/sfr/activity_service.js";
import ServicesService from "@/service/sfr/services_service.js";

import * as logger from "@/tools/logger.js";
import * as exceptions from "@/service/exception_to_message.js";

import {
  // convertPractitionerType,
  // convertPractitionerGender,
  reversePractitionerGender,
  exerciseCivilitiesEnum,
} from "@/views/practitioners/practitionerEnums.js";

// Nom digital du service médecin
const MEDECIN = "medecins";

export default {
  name: "CreatePractitioner",

  components: {
    Workflow,
    TitleAndReturnComponent,
    DeleteTableItemDialog,
    AlertNotSavedModifsComponent,
  },
  mixins: [WorkflowMixin, SnackBarMixin, AlertNotSavedModifsMixin],
  data() {
    return {
      /** le service des praticien */
      servicePractitioner: null,
      serviceCollaboratorPractitioner: null,
      serviceMedicalSpecialties: null,
      serviceDgUser: null,
      serviceCollaborators: null,
      serviceFunction: null,
      serviceEstablishments: null,
      serviceActivities: null,
      serviceService: null,

      /**les utilisateurs retournés lors de la recherche incrémentale. */
      users: [],
      /** la date de la dernière recherche (pour éviter les résultats croisés)*/
      lastSearchTimestamp: Date.now(),

      /** le collaborateur sélectionné */
      user: null,
      /** variable pour la saisie de recherche du collaborateur */
      search: "",

      /** variable pour l'affichage de chargement de données */
      loading: false,
      running: false,

      rppsVerified: {
        verified: false,
        isCollab: false,
      },

      /** les datas user correspondant à l'email sélectionné */
      collaboratorSelected: null,
      isCollaboratorPractitionner: false,
      collaboratorId: null,
      /** Le datas du praticien */
      practitionerIdpp: null,
      exerciseCivility: null,
      firstname: null,
      name: null,
      gender: null,
      presentation: null,

      email: null,
      establishmentName: null,
      activityName: null,
      primaryFunction: null,
      secondaryFunction: null,
      employeeTitle: null,

      // L'objet établissement complet
      establishment: null,
      // L'objet activité complet
      activity: null,

      professionName: null,
      medicalProfession: {
        codeMos: "",
        label: "",
        slug: "",
      },
      /** La liste des lieux d'exercice du praticien */
      practicesPlaces: [],
      /** La liste des spécialité médicales */
      medicalSpecialitiesList: [],

      /** La liste des noms de professions */
      professionsNames: [],
      /** Le nom de la profession sélectionné */
      selectedProfessionName: null,
      /** La liste des spécialités médicales  */
      medicalSpecialities: [],
      /** La spécialité médicale sélectionnée */
      selectedMedicalSpeciality: null,

      /** La liste des lieux d'exercice */
      practicePlacesList: [],
      /** Le lieu d'exercice sélectionné */
      selectedPracticePlace: null,

      /** L'agenda yesdoc à ajouter */
      yesdocAgendaToAdd: null,

      // Le lieux d'exercice à supprimer
      practicePlaceToDelete: null,
      // Affiche la dialog pour la confirmation de suppression du lieu d'exercice
      showDialogDeletePracticePlace: false,

      // La liste des agendas yesdoc à manipuler
      yesDocAgendas: [
        {
          practicePlaceId: null,
          agendas: [],
        },
      ],

      exerciseCivilities: [],
      genders: ["Homme", "Femme"],

      /** L'ensemble des services de la base */
      services: [],
      /** L'ensemble des fonctions de la base */
      functions: [],
      /** L'ensemble des identités de praticien */
      practitionerIdentities: [],

      errorsMessageIdpp: [],
      /** Les règles des différents champs */
      rules: {
        validateMedicalSpeciality: () =>
          this.checkMedicalSpeciality() ||
          "veuillez sélectionner une spécialité médicale.",
        validateRPPSFormat: () =>
          this.checkRPPSFormat ||
          "vous devez inscrire 9 caractères (Adeli) ou 11 caractères (RPPS) pour ce praticien.",
        practitionerIsExternal: () => {
          this.checkPractitionerIsExternal ||
            "le rpps entré appartient déjà à un praticien collaborateur.";
        },
      },
    };
  },

  methods: {
    /** Load des datas nécessaires au fonctionnement de la vue mais indépendante de la sélection du collaborateur */
    async load() {
      try {
        logger.debug("Chargement des données de la vue");
        this.loading = true;

        // Récupération de l'ensemble des service de la base
        this.services = await this.serviceService.getAllServices();
        // Récupération de l'ensemble des fonctions de la base
        this.functions = await this.serviceFunction.getAll();

        // Récupération de l'ensemble des civilités
        this.exerciseCivilities = exerciseCivilitiesEnum();
        // Récupération de l'ensemble des noms de professions
        this.professionsNames =
          await this.servicePractitioner.getAllProfessioNames();
        // Récupération de l'ensemble des lieux d'exercie
        this.practicePlacesList =
          await this.servicePractitioner.getAllPracticePlace();
        // Récupération de l'ensembles des spécialités médicales
        this.medicalSpecialitiesList =
          await this.serviceMedicalSpecialties.getAll();
      } catch (error) {
        logger.error("Errreur pendant le chargement des datas : ", error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Méthode exécutée à chaque changement du textfield de recherche */
    findUser(email) {
      if (!email || email == "") {
        //le texte de recherche est vide, on reset
        this.user = null;
        this.users = [];
        return;
      }

      /** Obligation de saisie à 5 caractères */
      if (email.length < 5) {
        this.users = [];
        return;
      }

      let now = Date.now();
      this.lastSearchTimestamp = now;

      this.loading = true;
      //on lance la recherche par email pour récupérer les collaborateurs avec uuid
      this.serviceCollaborators
        .searchCollaboratorByEmail(email, now)
        .then((results) => {
          //on vérifie que l'on ne garde pas une réponse plus ancienne (à cause du traitement de la requête)
          if (results.ts < this.lastSearchTimestamp) {
            return;
          }
          let users = [];
          results.users.forEach((user) => {
            users.push({ text: user.email, value: user });
          });

          this.users = users;
        })
        .catch((error) => {
          console.error("Problème lors de la recherche par email:" + error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /** Le collaborateur as été sélectionné, on charge ses datas */
    async selected(value) {
      try {
        if (!value) {
          this.user = null;
          return;
        }
        this.running = true;
        //recupère l'uuid du collaborateur
        this.collaboratorId = value.uuid;

        // Réinitialisation des datas
        this.resetDatas();

        // Récupération des datas du dguser
        this.collaboratorSelected = await this.serviceCollaborators.getByUuid(
          value.uuid
        );

        // Recherche si le collaborateur sélectionné est un pratitien collaborateur
        const collaboratorPractitioner =
          await this.serviceCollaboratorPractitioner.foundPractitioner(
            this.collaboratorSelected.uuid
          );
        if (collaboratorPractitioner) {
          this.isCollaboratorPractitionner = true;
        }

        // Initialisation des données du praticien
        this.initDatasPractitioner();
      } catch (error) {
        logger.error("Erreur chargement datas user :", error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.running = false;
      }
    },

    /** Initialisation des données de la vue */
    async initDatasPractitioner() {
      try {
        // Init des données
        this.name = this.collaboratorSelected.person.name;
        this.firstname = this.collaboratorSelected.person.firstname;
        this.gender = this.collaboratorSelected.person.gender;
        this.exerciseCivility = this.exerciseCivilities.find(
          (e) => e.value == null
        );

        this.practitionerIdpp = this.collaboratorSelected.person.rpps;

        // Lorsque la donnée issue de la dgapi n'a pas la données du rpps, on requête l'api practicien pour l'obtenir
        if (!this.practitionerIdpp || this.practitionerIdpp == "") {
          let name = `${this.name} ${this.firstname}`;
          // Récupération des données praticien avec la nom prénom
          let pract = await this.servicePractitioner.searchPractitionerByName(
            name,
            Date.now()
          );

          // Si l'on as 1 réponse on récupère le rpps du praticien
          if (pract.practitioners.length == 1) {
            this.practitionerIdpp = pract.practitioners[0].idPP;
          }
        }
      } catch (error) {
        logger.error("Error during init datas --> ", error);
      }

      // Chargement des données groupe
      await this.initDatasGroup();

      // Chargement de la partie profession
      this.initMedicalProfession();

      // Chargement du lieu d'exercice relatif à l'estab et l'activité du collaborateur
      await this.initPlacticePLace();
    },

    /** Initialise toutes la partie données groupe */
    async initDatasGroup() {
      logger.debug("Initialisation des datas groupe");
      try {
        if (this.collaboratorSelected.account) {
          this.email = this.collaboratorSelected.account.email || "";
        }

        // Recherche de l'établissement de référence
        this.establishmentName = "";
        if (this.collaboratorSelected.establishment) {
          // Récupération en base de l'établissement du user
          this.establishment = await this.serviceEstablishments.getById(
            this.collaboratorSelected.establishment
          );

          if (this.establishment) {
            this.establishmentName = this.establishment.digitalName;
          }
        }

        // Recherche de l'activité de référence
        this.activityName = "";
        if (this.collaboratorSelected.activity) {
          // Récupération en base de l'activité du user
          this.activity = await this.serviceActivities.getActivityById(
            this.collaboratorSelected.activity
          );

          if (this.activity) {
            this.activityName = this.activity.name;
          }
        }

        // Recherche de la fonction primaire
        this.primaryFunction = "";
        if (this.collaboratorSelected.function) {
          let foundFunc = this.functions.find(
            (f) => f.id == this.collaboratorSelected.function
          );
          if (foundFunc) {
            this.primaryFunction = foundFunc.name;
          }
        }

        // Recherche des fonctions secondaires
        this.secondaryFunction = "";
        if (this.collaboratorSelected.secondaryFunctions) {
          // Parcours de l'ensemble des fonctions secondaires et présente les fonctions
          for (
            let i = 0;
            i < this.collaboratorSelected.secondaryFunctions.length;
            i++
          ) {
            let foundSecondary = this.functions.find(
              (f) => f.id == this.collaboratorSelected.secondaryFunctions[i]
            );

            if (i == 0) {
              this.secondaryFunction = foundSecondary.name;
            } else {
              this.secondaryFunction += `, ${foundSecondary.name}`;
            }
          }
        }

        // Le titre employé
        this.employeeTitle = this.collaboratorSelected.employeeTitle || "";
      } catch (error) {
        logger.error("Error during init datas groupe --> ", error);
      }
    },

    /** Initialise toutes la partie de la profession médicale et des spécialités médicales */
    initMedicalProfession() {
      // Récupération de la fonction qui sert de base à la présaisie de la profession
      if (this.collaboratorSelected.function) {
        let foundFunc = this.functions.find(
          (f) => f.id == this.collaboratorSelected.function
        );

        if (foundFunc) {
          // Récupération de la profession avec la définition de la fonction
          this.selectedProfessionName = this.professionsNames.find(
            (p) => p.label == foundFunc.definition
          );
          logger.debug("profession sélected", this.selectedProfessionName);
          // Sélection de la profession médicale et des spécialitées médicales en fonction de la profession pré-saisie.
          this.selectedProfessionNameAction();
        }
      }
    },

    /** Initialise le lieux d'exercice */
    async initPlacticePLace() {
      if (this.establishment && this.activity) {
        // Récupération du lieux d'exercice correspondant à l'établissement et l'activité du collaborateur
        let practicePlace =
          await this.servicePractitioner.getPlacticePlaceFromEstablishmentAndActivity(
            this.establishment.id,
            this.activity.id
          );

        if (practicePlace) {
          // Si trouvé, on l'ajoute directement aux lieu d'exercices pour la pré-saisie
          this.practicesPlaces.push(practicePlace);
        }
      }
    },

    /** Sauvegarde des données de l'utilisateur */
    async save() {
      try {
        this.running = true;

        // Récupération de l'id (ancien) du dguser
        const dguserId = await this.serviceDgUser.searchDgUserByEmailWithId(
          this.email
        );
        if (dguserId.users?.length != 1 || !dguserId.users[0]?.id) {
          throw Error("Erreur de récupération de l'id .");
        }

        let entity = {};
        entity.dgUserId = dguserId.users[0].id;
        entity.collaboratorId = this.collaboratorId;
        entity.exerciseCivility = this.exerciseCivility.value;
        entity.firstname = this.firstname;
        entity.name = this.name;
        entity.gender = reversePractitionerGender(this.gender);
        entity.idPP = this.practitionerIdpp;
        entity.medicalProfessionCodeMos = this.medicalProfession.codeMos;

        // Ajout de la spécialité médicale si sélectionné
        entity.medicalSpecialtyId = null;
        if (this.selectedMedicalSpeciality) {
          let found = this.medicalSpecialitiesList.find(
            (m) => m.nomenclature == this.selectedMedicalSpeciality.code
          );
          entity.medicalSpecialtyId = found.id;
        }

        // Ajout du lieux d'exercice principal.
        let principalPracticePlace = this.practicesPlaces[0];
        entity.practicePlaceIds = [principalPracticePlace.practicePlaceId];

        // Création du praticien collaborateur
        await this.serviceCollaboratorPractitioner.createPractitionerCollaborator(
          entity
        );

        // Si une présentation est entrée, il faut l'ajouter en patchant le praticien
        if (this.presentation) {
          await this.servicePractitioner.saveIdentity(this.practitionerIdpp, {
            presentation: this.presentation,
          });
        }

        // Présence d'agenda à ajouter ?
        if (this.yesDocAgendas) {
          // Parcours des agendas par lieu d'exercice
          for (let practicePlaceAgenda of this.yesDocAgendas) {
            // Parcours des agendas du lieu d'exercice
            for (let agenda of practicePlaceAgenda.agendas) {
              let agendaEntity = {};
              agendaEntity.practicePlaceId =
                practicePlaceAgenda.practicePlaceId;
              agendaEntity.agendaId = agenda;

              // Sauvegarde de chaque agenda
              await this.servicePractitioner.addAgendaToPracticePlace(
                this.practitionerIdpp,
                agendaEntity
              );
            }
          }
        }

        // La sauvegarde a eu lieu, on peux réinitialiser les données
        this.resetDatas();
        this.users = [];
        this.user = null;
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "enregistrement des données du praticien : " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.running = false;
      }
    },

    /** Réinitialisation des données de la vue */
    resetDatas() {
      this.collaboratorSelected = null;
      this.isCollaboratorPractitionner = false;

      this.practitionerIdpp = null;
      this.exerciseCivility = null;
      this.firstname = null;
      this.name = null;
      this.gender = null;
      this.presentation = null;

      this.email = null;
      this.establishmentName = null;
      this.activityName = null;
      this.primaryFunction = null;
      this.secondaryFunction = null;
      this.employeeTitle = null;

      this.establishment = null;
      this.activity = null;

      this.professionName = null;
      this.medicalProfession.codeMos = "";
      this.medicalProfession.label = "";
      this.medicalProfession.slug = "";

      this.practicesPlaces = [];
      this.selectedProfessionName = null;
      this.selectedMedicalSpeciality = null;
      this.selectedPracticePlace = null;
      this.yesdocAgendaToAdd = null;
      this.practicePlaceToDelete = null;

      this.yesDocAgendas = [];

      this.showDialogDeletePracticePlace = false;

      this.load();
    },

    // Action de sélection d'une profession médicale
    selectedProfessionNameAction() {
      // Si un nom de profession à été choisi, on redéfini la profession médicale
      if (this.selectedProfessionName) {
        // On récupère la profession médicale dans l'objet
        this.medicalProfession = this.selectedProfessionName.medicalProfession;
        this.medicalSpecialities =
          this.selectedProfessionName.medicalSpecialties;

        this.selectedMedicalSpeciality = null;

        if (this.medicalSpecialities.length > 0) {
          // Dans le cas où on as qu'une seule spécialité médical, on la sélectionne d'office.
          if (this.medicalSpecialities.length == 1) {
            this.selectedMedicalSpeciality = this.medicalSpecialities[0];
          }
        }
      }
    },

    /** Règle de vérification de saisie des spécialités médicale */
    checkMedicalSpeciality() {
      let result = true;
      if (this.selectedProfessionName) {
        if (
          this.medicalSpecialities.length > 1 &&
          !this.selectedMedicalSpeciality
        ) {
          result = false;
        }
      }
      return result;
    },

    /** Ajoute un lieux d'exercice à la liste de ceux du praticien */
    addPracticePlace() {
      if (this.selectedPracticePlace) {
        this.practicesPlaces.push(this.selectedPracticePlace);

        this.selectedPracticePlace = null;
      }
    },

    /** Supprime un lieux d'exercice de la liste */
    deletePracticePlace(practicePlace) {
      this.practicePlaceToDelete = practicePlace;

      // Recherche si le lieu d'exercice est celui de rattachement
      if (this.establishment && this.activity) {
        if (
          this.establishment.id == practicePlace.establishmentId &&
          this.activity.id == practicePlace.activityId
        ) {
          this.showDialogDeletePracticePlace = true;
        } else {
          this.onDeletePracticePlaceConfirmed();
        }
      }
    },

    /** Confirmation de la suppression du lieu d'exercice */
    onDeletePracticePlaceConfirmed() {
      // Suppression du lieu dans la liste
      this.practicesPlaces.splice(
        this.practicesPlaces.findIndex(
          (p) => p.practicePlaceId == this.practicePlaceToDelete.practicePlaceId
        ),
        1
      );
      this.practicePlaceToDelete = null;
    },

    // Fournit l'adresse du practice place en param
    addressOfPracticePlace(practicePlace) {
      let address = {
        address: "",
        postalCode: "",
        city: "",
      };
      // Si le lieux d'exercice n'est pas null et l'adresse qu'il contient aussi
      // on fabrique notre objet adresse
      if (practicePlace) {
        if (practicePlace.address) {
          address.address = practicePlace.address.address;
          address.postalCode = practicePlace.address.postalCode;
          address.city = practicePlace.address.city;
        }
      }
      return address;
    },

    /** Retourne la liste des agendas du lieu d'exercice en param */
    agendaOfPracticePlace(practicePlace) {
      let result = [];

      if (practicePlace) {
        // Recherche l'objet agenda associé au lieu d'exercice
        let found = this.yesDocAgendas.find(
          (y) => y.practicePlaceId == practicePlace.practicePlaceId
        );

        // Si trouvé on copie les agendas
        if (found) {
          result.push(...found.agendas);
        }
      }

      return result;
    },

    /** Ajoute un agenda yesdoc à la liste du praticien */
    addYesdocAgenda(practicePlace) {
      if (practicePlace) {
        // Recherche l'objet agenda associé au lieu d'exercice
        let found = this.yesDocAgendas.find(
          (y) => y.practicePlaceId == practicePlace.practicePlaceId
        );

        // Si on ne le trouve pas, on crée un objet agenda
        if (!found) {
          found = {};
          found.practicePlaceId = practicePlace.practicePlaceId;
          found.agendas = [];
          // Ajout de l'objet agenda créé dans le tableau des agendas
          this.yesDocAgendas.push(found);
        }

        // Ajout de l'agenda dans la liste des agendas du lieu d'exercice
        found.agendas.push(this.yesdocAgendaToAdd);

        // Reset de la saisie
        this.yesdocAgendaToAdd = null;
      }
    },

    /** Suppression d'un agenda dans un lieu d'exercice */
    deleteYesdocAgenda(practicePlace, agenda) {
      if (practicePlace && agenda) {
        // Recherche l'objet agenda associé au lieu d'exercice
        let found = this.yesDocAgendas.find(
          (y) => y.practicePlaceId == practicePlace.practicePlaceId
        );

        // Supprime l'agenda dans le lieu d'exercice
        found.agendas.splice(
          found.agendas.findIndex((f) => f == agenda),
          1
        );
      }
    },

    /** Détermine si le collaborateur appartient au service Médecin */
    userHasMedecinService() {
      let result = false;

      // Présence du collaborateur
      if (this.collaboratorSelected) {
        // Concaténation de tous les fonctions du collaborateur
        let functionCollab = [];
        functionCollab.push(this.collaboratorSelected.function);
        functionCollab.push(...this.collaboratorSelected.secondaryFunctions);

        // Parcours les fonction du collaborateur
        for (let func of functionCollab) {
          // Récupère la fonction du collaborateur dans la liste
          let foundFunction = this.functions.find((f) => f.id == func);

          if (foundFunction) {
            // Récupération du service associé à la fonction
            let foundService = this.services.find(
              (s) => s.id == foundFunction.serviceId
            );

            if (foundService) {
              // Est-ce le service Médecin ????
              if (foundService.digitalName == MEDECIN) {
                // C'est la service Médecin, on coupe la boucle, pas besoin de plus.
                result = true;
                break;
              }
            }
          }
        }
      }

      return result;
    },

    /** Vérify que le rpps entré n'est pas déjà attribué à un praticien collab */
    async verifyPractitionerCollaboratorRpps() {
      if (this.practitionerIdpp && this.checkRPPSFormat) {
        const pract =
          await this.servicePractitioner.isPractitionerCollaboratorRpps(
            this.practitionerIdpp
          );
        if (pract) {
          this.rppsVerified.verified = true;
          this.rppsVerified.isCollab = true;
          return true;
        } else {
          this.rppsVerified.verified = true;
          this.rppsVerified.isCollab = false;
        }
      }
      this.rppsVerified.verified = false;
      this.rppsVerified.isCollab = false;
      return false;
    },
  },

  computed: {
    /** Désactive la combo des spécialités médicales si la liste est vide */
    disableComboMedicalSpeciality() {
      if (this.medicalSpecialities.length <= 1) {
        return true;
      }
      return false;
    },

    /** Désactive la combo d'ajout des lieux d'exercice */
    disabledComboPracticePlace() {
      let result = false;

      // On ne peut ajouter qu'un lieu d'exercice
      if (this.practicesPlaces.length == 1) {
        result = true;
      }

      return result;
    },

    /** Retourne les lieux d'exercice disponibles pour le praticien */
    availablesPracticesPlaces() {
      let available = [];

      // Récupération de tous les lieux d'exercice que possede déjà la praticien
      let ids = this.practicesPlaces.map((p) => p.practicePlaceId);
      // Filtre tout les practice place non associé au praticien
      available = this.practicePlacesList.filter(
        (p) => !ids.includes(p.practicePlaceId)
      );

      return available;
    },

    /** Retourne l'autorisation d'ajout d'un practice place */
    canAddPracticePlace() {
      if (this.selectedPracticePlace) {
        return true;
      }
      return false;
    },

    /** Retourne la possibilité d'affichage de l'encart des datas du pratcien à créer */
    viewDatasToCreate() {
      let view = true;

      // le user doit être trouvé
      if (!this.user) {
        view = false;
      }

      // Les datas du collaborateur ont été trouvée
      if (!this.collaboratorSelected) {
        view = false;
      }

      // le user est déjà praticien collab ?
      if (this.userIsCollaboraterPractitioner) {
        view = false;
      }

      return view;
    },

    /** Détermine si le DGcollaborateur est déjà praticien collaborateur */
    userIsCollaboraterPractitioner() {
      let result = false;

      if (this.collaboratorSelected && this.isCollaboratorPractitionner) {
        result = true;
      }
      return result;
    },

    /** Retourne l'affichage du message d'erreur du collaborateur qui n'a pas le service médecin */
    viewMessageUserHasNotServiceMedecin() {
      let result = false;

      if (this.collaboratorSelected && !this.userHasMedecinService()) {
        result = true;
      }
      return result;
    },

    /** Retourne la visibilité du bouton de suppression d'un lieu d'exercice */
    viewDeletePracticePlace() {
      // Un praticien doit toujours posséder un lieu d'exercice
      if (this.practicesPlaces.length <= 1) {
        return false;
      }

      return true;
    },

    /** Retourne l'autorisation d'ajout d'un agenda yesdoc */
    canAddYesdocAgenda() {
      if (this.yesdocAgendaToAdd) {
        return true;
      }
      return false;
    },

    /** Règle de vérification du format du numéro rpps du praticien */
    checkRPPSFormat() {
      let result = true;

      if (this.practitionerIdpp) {
        // Vérifie que la saisie comporte bien 11 chiffres pour le rpps ou 9 pour le adeli
        if (
          this.practitionerIdpp.length != 11 &&
          this.practitionerIdpp.length != 9
        ) {
          result = false;
        }
      }
      return result;
    },

    /** Règle de vérification de praticien externe */
    checkPractitionerIsExternal() {
      let result = true;

      if (
        this.practitionerIdpp &&
        this.rppsVerified.verified &&
        this.rppsVerified.isCollab
      ) {
        result = false;
      }
      return result;
    },

    /** Retourne s'il est possible de sauvegarder */
    completed() {
      if (!this.collaboratorSelected) {
        return false;
      }

      // le genre
      if (!this.gender) {
        return false;
      }
      // Le nom
      if (!this.name) {
        return false;
      }
      // Le prénom
      if (!this.firstname) {
        return false;
      }
      // L'idpp
      if (!this.practitionerIdpp) {
        return false;
      } else {
        if (!this.checkRPPSFormat) {
          return false;
        }
        if (!this.checkPractitionerIsExternal) {
          return false;
        }
      }
      // La profession
      if (!this.selectedProfessionName) {
        return false;
      } else {
        if (!this.checkMedicalSpeciality()) {
          return false;
        }
      }
      // Le lieu d'exercice
      if (this.practicesPlaces.length == 0) {
        return false;
      }
      return true;
    },
  },

  watch: {
    /** Méthode de surveillance de saisie de recherche du collaborateur */
    search(value) {
      // Recherche incrémentale du collaborateur
      this.findUser(value);
    },

    /** Méthode de vérification si le rpps est dans le fichier externe */
    practitionerIdpp(value) {
      this.errorsMessageIdpp = [];
      if (this.practitionerIdpp) {
        if (this.checkRPPSFormat) {
          logger.debug("Idpp is in external file ? " + value);

          // vérification d'idpp déjà attribué à un collab
          this.verifyPractitionerCollaboratorRpps().then((value) => {
            if (value) {
              this.errorsMessageIdpp = [
                "le rpps entré appartient déjà à un praticien collaborateur.",
              ];
            }
          });
          // Vérification d'idpp existant dans le fichier des pract
          this.servicePractitioner
            .searchPractitionerByIdpp(this.practitionerIdpp)
            .catch(() => {
              this.errorsMessageIdpp = [
                "le rpps entré est inconnu dans le fichier national.",
              ];
            });
          // }
        }
      }
    },
  },
  mounted() {
    //on affiche le bouton retour
    this.showBackButton = true;

    this.addStepForWorkflow("Choisir un périmètre");
    this.addStepForWorkflow("Consulter la configuration");
    this.nextStepForWorkflow();

    this.servicePractitioner = new PractitionnerService(
      this.$api.getPractitionerApi()
    );
    this.serviceCollaboratorPractitioner = new CollaboratorPractitionerService(
      this.$api.getCollaboratorPractitionerApi()
    );
    this.serviceMedicalSpecialties = new MedicalSpecialtiesService(
      this.$api.getMedicalSpecialitiesApi()
    );
    this.serviceDgUser = new UsersService(this.$api.getUsersApi());
    this.serviceCollaborators = new CollaboratorsService(
      this.$api.getCollaboratorsApi()
    );
    this.serviceFunction = new FonctionService(this.$api.getFunctionApi());
    this.serviceEstablishments = new EstablishmentsService(this.$api);
    this.serviceActivities = new ActivityService(this.$api);
    this.serviceService = new ServicesService(this.$api);

    this.load();
  },
};
</script>

<style>
.inputPrice input[type="number"] {
  -moz-appearance: textfield;
}
.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
