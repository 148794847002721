var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-center", attrs: { flat: "", tile: "" } },
        [
          _c("Workflow", {
            attrs: {
              width: "600",
              height: "180",
              steps: _vm.workflowSteps,
              currentStep: _vm.workflowIndex,
              labelWidth: 200,
              lineWidth: 140,
            },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-center" },
                [
                  _c("TitleAndReturnComponent", {
                    attrs: { title: "Création d'un praticien" },
                  }),
                ],
                1
              ),
              _c("v-progress-linear", {
                attrs: {
                  indeterminate: "",
                  active: _vm.loading || _vm.running,
                },
              }),
              _c(
                "v-card",
                { staticClass: "mx-auto", attrs: { flat: "", outlined: "" } },
                [
                  _c(
                    "v-card-title",
                    [
                      _c("v-row", { attrs: { "no-gutters": "" } }, [
                        _c("div", { staticClass: "font-weight-regular" }, [
                          _vm._v(" Créer un praticien collaborateur "),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "pb-1" },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.users,
                          "search-input": _vm.search,
                          "no-data-text": "",
                          clearable: "",
                          loading: _vm.loading || _vm.running,
                          label: "Saisir l'adresse mail du collaborateur",
                        },
                        on: {
                          "update:searchInput": function ($event) {
                            _vm.search = $event
                          },
                          "update:search-input": function ($event) {
                            _vm.search = $event
                          },
                          input: _vm.selected,
                        },
                        model: {
                          value: _vm.user,
                          callback: function ($$v) {
                            _vm.user = $$v
                          },
                          expression: "user",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.viewMessageUserHasNotServiceMedecin
                    ? _c("v-card-text", { staticClass: "pb-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-h6 font-weight-regular error--text",
                          },
                          [
                            _vm._v(
                              " Le collaborateur sélectionné ne possède pas de fonction associée au service Médecin. "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm.userIsCollaboraterPractitioner
                    ? _c("v-card-text", { staticClass: "pb-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-h6 font-weight-regular error--text",
                          },
                          [
                            _vm._v(
                              " Le collaborateur sélectionné est déjà un praticien collaborateur. "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm.viewDatasToCreate
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "pt-1" },
                            [
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "", justify: "end" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "red--text text-body-2",
                                      attrs: { justify: "end" },
                                    },
                                    [_vm._v(" * ")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-body-2 ml-1",
                                      attrs: { justify: "end" },
                                    },
                                    [_c("em", [_vm._v(" champs obligatoires")])]
                                  ),
                                ]
                              ),
                              _c(
                                "v-row",
                                {
                                  staticStyle: { "flex-wrap": "nowrap" },
                                  attrs: { "no-gutters": "", align: "center" },
                                },
                                [
                                  _c("v-autocomplete", {
                                    staticClass: "flex-grow-0 flex-shrink-1",
                                    attrs: {
                                      items: _vm.exerciseCivilities,
                                      "item-text": "name",
                                      "return-object": "",
                                    },
                                    model: {
                                      value: _vm.exerciseCivility,
                                      callback: function ($$v) {
                                        _vm.exerciseCivility = $$v
                                      },
                                      expression: "exerciseCivility",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-h6 font-weight-bold mx-4",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.firstname) +
                                          " " +
                                          _vm._s(_vm.name) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("v-autocomplete", {
                                    staticClass: "flex-grow-0 flex-shrink-1",
                                    attrs: { items: _vm.genders },
                                    model: {
                                      value: _vm.gender,
                                      callback: function ($$v) {
                                        _vm.gender = $$v
                                      },
                                      expression: "gender",
                                    },
                                  }),
                                  _c("span", { staticClass: "red--text" }, [
                                    _vm._v("*"),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  attrs: { "no-gutters": "", align: "center" },
                                },
                                [
                                  _c("v-col", { attrs: { cols: "3" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "my-2 capitalize font-weight-regular",
                                      },
                                      [
                                        _vm._v(" numéro RPPS "),
                                        _c(
                                          "span",
                                          { staticClass: "red--text" },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v(" : "),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "9" } },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "inputPrice",
                                        attrs: {
                                          placeholder: "Le numéro RPPS",
                                          dense: "",
                                          rules: [
                                            _vm.rules.validateRPPSFormat,
                                            _vm.rules.practitionerIsExternal,
                                          ],
                                          "error-messages":
                                            _vm.errorsMessageIdpp,
                                        },
                                        on: {
                                          blur: _vm.verifyPractitionerCollaboratorRpps,
                                        },
                                        model: {
                                          value: _vm.practitionerIdpp,
                                          callback: function ($$v) {
                                            _vm.practitionerIdpp = $$v
                                          },
                                          expression: "practitionerIdpp",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  attrs: { "no-gutters": "", align: "center" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-2 capitalize font-weight-regular",
                                    },
                                    [
                                      _vm._v(
                                        " présentation sur le site Avec : "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "v-row",
                                {
                                  attrs: { "no-gutters": "", align: "center" },
                                },
                                [
                                  _c("v-textarea", {
                                    staticClass: "mb-6",
                                    attrs: {
                                      filled: "",
                                      placeholder:
                                        "Présentation sur le site Avec",
                                    },
                                    model: {
                                      value: _vm.presentation,
                                      callback: function ($$v) {
                                        _vm.presentation = $$v
                                      },
                                      expression: "presentation",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("v-divider"),
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                {
                                  attrs: { "no-gutters": "", align: "center" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "capitalize text-h6 font-weight-regular",
                                    },
                                    [_vm._v(" Les données groupe : ")]
                                  ),
                                ]
                              ),
                              _c(
                                "v-row",
                                {
                                  attrs: { "no-gutters": "", align: "center" },
                                },
                                [
                                  _c("v-col", { attrs: { cols: "3" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "my-2 capitalize font-weight-regular",
                                      },
                                      [_vm._v("email :")]
                                    ),
                                  ]),
                                  _c("v-col", { attrs: { cols: "9" } }, [
                                    _c("div", { staticClass: "my-2" }, [
                                      _vm._v(_vm._s(_vm.email)),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  attrs: { "no-gutters": "", align: "center" },
                                },
                                [
                                  _c("v-col", { attrs: { cols: "3" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "my-2 capitalize font-weight-regular",
                                      },
                                      [
                                        _vm._v(
                                          " établissement de rattachement : "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("v-col", { attrs: { cols: "9" } }, [
                                    _c("div", { staticClass: "my-2" }, [
                                      _vm._v(_vm._s(_vm.establishmentName)),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  attrs: { "no-gutters": "", align: "center" },
                                },
                                [
                                  _c("v-col", { attrs: { cols: "3" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "my-2 capitalize font-weight-regular",
                                      },
                                      [_vm._v(" activité : ")]
                                    ),
                                  ]),
                                  _c("v-col", { attrs: { cols: "9" } }, [
                                    _c("div", { staticClass: "my-2" }, [
                                      _vm._v(_vm._s(_vm.activityName)),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  attrs: { "no-gutters": "", align: "center" },
                                },
                                [
                                  _c("v-col", { attrs: { cols: "3" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "my-2 capitalize font-weight-regular",
                                      },
                                      [_vm._v(" fonction : ")]
                                    ),
                                  ]),
                                  _c("v-col", { attrs: { cols: "9" } }, [
                                    _c("div", { staticClass: "my-2" }, [
                                      _vm._v(_vm._s(_vm.primaryFunction)),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  attrs: { "no-gutters": "", align: "center" },
                                },
                                [
                                  _c("v-col", { attrs: { cols: "3" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "my-2 capitalize font-weight-regular",
                                      },
                                      [_vm._v(" fonction(s) secondaire(s) : ")]
                                    ),
                                  ]),
                                  _c("v-col", { attrs: { cols: "9" } }, [
                                    _c("div", { staticClass: "my-2" }, [
                                      _vm._v(_vm._s(_vm.secondaryFunction)),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  attrs: { "no-gutters": "", align: "center" },
                                },
                                [
                                  _c("v-col", { attrs: { cols: "3" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "my-2 capitalize font-weight-regular",
                                      },
                                      [_vm._v(" titre employé : ")]
                                    ),
                                  ]),
                                  _c("v-col", { attrs: { cols: "9" } }, [
                                    _c("div", { staticClass: "my-2" }, [
                                      _vm._v(_vm._s(_vm.employeeTitle)),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-divider"),
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                {
                                  attrs: { "no-gutters": "", align: "center" },
                                },
                                [
                                  _c("v-col", { attrs: { cols: "3" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "capitalize font-weight-regular",
                                      },
                                      [
                                        _vm._v(" Profession "),
                                        _c(
                                          "span",
                                          { staticClass: "red--text" },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v(" : "),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "9" } },
                                    [
                                      _c("v-autocomplete", {
                                        staticClass:
                                          "flex-grow-0 flex-shrink-1",
                                        attrs: {
                                          placeholder:
                                            "Sélectionner un nom de profession",
                                          "item-text": "label",
                                          items: _vm.professionsNames,
                                          "no-data-text":
                                            "aucun nom de profession",
                                          clearable: "",
                                          "return-object": "",
                                        },
                                        on: {
                                          input:
                                            _vm.selectedProfessionNameAction,
                                        },
                                        model: {
                                          value: _vm.selectedProfessionName,
                                          callback: function ($$v) {
                                            _vm.selectedProfessionName = $$v
                                          },
                                          expression: "selectedProfessionName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  attrs: { "no-gutters": "", align: "center" },
                                },
                                [
                                  _c("v-col", { attrs: { cols: "3" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "capitalize font-weight-regular",
                                      },
                                      [_vm._v(" Profession médicale : ")]
                                    ),
                                  ]),
                                  _c("v-col", { attrs: { cols: "9" } }, [
                                    _c("div", { staticClass: "my-2" }, [
                                      _vm._v(
                                        _vm._s(_vm.medicalProfession.label)
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-2",
                                  attrs: { "no-gutters": "", align: "center" },
                                },
                                [
                                  _c("v-col", { attrs: { cols: "3" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "capitalize font-weight-regular",
                                      },
                                      [
                                        _vm._v(" Spécialité médicale "),
                                        !_vm.disableComboMedicalSpeciality
                                          ? _c(
                                              "span",
                                              { staticClass: "red--text" },
                                              [_vm._v("*")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" : "),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "9" } },
                                    [
                                      _c("v-autocomplete", {
                                        staticClass:
                                          "flex-grow-0 flex-shrink-1",
                                        attrs: {
                                          placeholder: "",
                                          "item-text": "name",
                                          items: _vm.medicalSpecialities,
                                          "no-data-text":
                                            "aucune spécialité médicale",
                                          disabled:
                                            _vm.disableComboMedicalSpeciality,
                                          rules: [
                                            _vm.rules.validateMedicalSpeciality,
                                          ],
                                          "return-object": "",
                                        },
                                        model: {
                                          value: _vm.selectedMedicalSpeciality,
                                          callback: function ($$v) {
                                            _vm.selectedMedicalSpeciality = $$v
                                          },
                                          expression:
                                            "selectedMedicalSpeciality",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("v-divider"),
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                {
                                  attrs: { "no-gutters": "", align: "center" },
                                },
                                [
                                  _c("v-col", { attrs: { cols: "3" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "capitalize font-weight-regular",
                                      },
                                      [_vm._v(" Ajouter un lieu d'exercice : ")]
                                    ),
                                  ]),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-autocomplete", {
                                        staticClass:
                                          "flex-grow-0 flex-shrink-1",
                                        attrs: {
                                          placeholder:
                                            "Sélectionner un lieux d'exercice",
                                          "item-text": "name",
                                          "item-value": "name",
                                          items: _vm.availablesPracticesPlaces,
                                          disabled:
                                            _vm.disabledComboPracticePlace,
                                          "no-data-text":
                                            "aucuns lieux d'exercice",
                                          "return-object": "",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.selectedPracticePlace,
                                          callback: function ($$v) {
                                            _vm.selectedPracticePlace = $$v
                                          },
                                          expression: "selectedPracticePlace",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "1" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            icon: "",
                                            disabled: !_vm.canAddPracticePlace,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addPracticePlace()
                                            },
                                          },
                                        },
                                        [_c("v-icon", [_vm._v("mdi-plus")])],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mt-4",
                                  attrs: { "no-gutters": "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "3" } },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            "no-gutters": "",
                                            align: "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "capitalize text-h6 font-weight-regular",
                                            },
                                            [
                                              _vm._v(" Le lieu d'exercice "),
                                              _c(
                                                "span",
                                                { staticClass: "red--text" },
                                                [_vm._v("*")]
                                              ),
                                              _vm._v(" : "),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "mt-6",
                                      attrs: { cols: "9" },
                                    },
                                    _vm._l(
                                      _vm.practicesPlaces,
                                      function (pp, i) {
                                        return _c(
                                          "div",
                                          { key: i },
                                          [
                                            _c(
                                              "v-row",
                                              {
                                                staticClass: "mb-1",
                                                attrs: {
                                                  "no-gutters": "",
                                                  align: "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "2" } },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "capitalize font-weight-regular",
                                                      },
                                                      [_vm._v("Nom :")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "9" } },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-subtitle-1 font-weight-bold",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(pp.name) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "1" } },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deletePracticePlace(
                                                              pp
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-window-close"
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              {
                                                attrs: {
                                                  "no-gutters": "",
                                                  align: "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "2",
                                                      "align-self": "start",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "capitalize font-weight-regular",
                                                      },
                                                      [_vm._v(" Adresse : ")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "10" } },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "font-weight-regular",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.addressOfPracticePlace(
                                                                pp
                                                              ).address
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "font-weight-regular",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.addressOfPracticePlace(
                                                                pp
                                                              ).postalCode
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.addressOfPracticePlace(
                                                                pp
                                                              ).city
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              {
                                                attrs: {
                                                  "no-gutters": "",
                                                  align: "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "2",
                                                      "align-self": "start",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "capitalize font-weight-regular",
                                                      },
                                                      [_vm._v(" Téléphone : ")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "10" } },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "font-weight-regular",
                                                      },
                                                      [_vm._v(_vm._s(pp.phone))]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              {
                                                staticClass: "my-4",
                                                attrs: {
                                                  "no-gutters": "",
                                                  align: "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "2",
                                                      "align-self": "start",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "capitalize font-weight-regular",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " agendas YESDOC : "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "10" } },
                                                  [
                                                    _vm._l(
                                                      _vm.agendaOfPracticePlace(
                                                        pp
                                                      ),
                                                      function (agenda, i) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: "A" + i,
                                                            staticClass:
                                                              "font-weight-regular",
                                                          },
                                                          [
                                                            _c(
                                                              "v-row",
                                                              {
                                                                attrs: {
                                                                  "no-gutters":
                                                                    "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "11",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          agenda
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "1",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          icon: "",
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.deleteYesdocAgenda(
                                                                                pp,
                                                                                agenda
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-window-close"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    _c(
                                                      "v-row",
                                                      {
                                                        attrs: {
                                                          "no-gutters": "",
                                                          align: "center",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "11",
                                                            },
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                placeholder:
                                                                  "L'uuid de l'agenda yesdoc",
                                                                dense: "",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.yesdocAgendaToAdd,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.yesdocAgendaToAdd =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "yesdocAgendaToAdd",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "1",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                  icon: "",
                                                                  disabled:
                                                                    !_vm.canAddYesdocAgenda,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.addYesdocAgenda(
                                                                        pp
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-plus"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm.practicesPlaces.length > 1
                                              ? _c("v-divider", {
                                                  staticClass: "my-4",
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-2 px-4 btn",
                                  attrs: {
                                    outlined: "",
                                    color: "primary",
                                    disabled: !_vm.completed || _vm.loading,
                                  },
                                  on: { click: _vm.save },
                                },
                                [
                                  _c("div", { staticClass: "capitalize" }, [
                                    _vm._v("enregistrer"),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("DeleteTableItemDialog", {
        attrs: {
          label:
            "Le lieu d'exercice se trouve au sein de l'établissement de rattachement du praticien.",
          item: _vm.practicePlaceToDelete,
          visible: _vm.showDialogDeletePracticePlace,
        },
        on: {
          "update:visible": function ($event) {
            _vm.showDialogDeletePracticePlace = $event
          },
          confirmed: function ($event) {
            return _vm.onDeletePracticePlaceConfirmed()
          },
        },
      }),
      _c("AlertNotSavedModifsComponent", {
        attrs: { show: _vm.showAlertQuit },
        on: { quit: _vm.onQuitAlert, notquit: _vm.onNotQuitAlert },
      }),
      _c(
        "v-snackbar",
        {
          attrs: {
            color: _vm.snackbarColor,
            timeout: _vm.snackbarTimeout,
            left: _vm.snackbarLeft,
            right: _vm.snackbarRight,
            top: _vm.snackbarTop,
            bottom: _vm.snackbarBottom,
          },
          model: {
            value: _vm.snackbarVisible,
            callback: function ($$v) {
              _vm.snackbarVisible = $$v
            },
            expression: "snackbarVisible",
          },
        },
        [_vm._v(_vm._s(_vm.snackbarMessage))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }